import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import { CenterContent } from "../ContentBlock/styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <CenterContent>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={12}>
                <Language>{t("Contact")}</Language>
                <Large to="/">{t("Tell us everything")}</Large>
                <Para>
                  {t(`Do you have any question? Feel free to reach out.`)}
                </Para>
                <a href="mailto:zian@customchat-ai.com">
                  <Chat>{t(`Let's Chat`)}</Chat>
                </a>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Title>{t("Policy")}</Title>
                <Large to="/">{t("Application Security")}</Large>
                <Large to="/">{t("Software Principles")}</Large>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Empty />
                <Large to="/">{t("Support Center")}</Large>
                <Large to="/">{t("Customer Support")}</Large>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={12}>
                <Empty />
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Title>{t("Company")}</Title>
                <Large to="/">{t("About")}</Large>
                <Large to="/">{t("Blog")}</Large>
                <Large to="/">{t("Press")}</Large>
                <Large to="/">{t("Careers & Culture")}</Large>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Label htmlFor="select-lang">{t("Language")}</Label>
                <LanguageSwitchContainer>
                  <LanguageSwitch onClick={() => handleChange("en")}>
                    <SvgIcon
                      src="united-states.svg"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("zh")}>
                    <SvgIcon
                      src="China.svg"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
              </Col>
            </Row>
          </CenterContent>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <CenterContent>
            <Row
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                    src="customchat-ai-logo.svg"
                    aria-label="homepage"
                    width="50px"
                    height="50px"
                  />
                </LogoContainer>
              </NavLink>
              <FooterContainer>
              <SocialLink
                href="https://www.smart-msl.com/"
                src="github.svg"
              />
              <SocialLink
                href="https://www.smart-msl.com/"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.smart-msl.com/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://www.smart-msl.com/"
                src="medium.svg"
              />
              <a
                href="https://www.smart-msl.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
              </a>
            </FooterContainer>
            </Row>
          </CenterContent>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
